import React from 'react';
import Slider from 'react-slick';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { mq, breakpoint } from 'style/variables';
import sizes from 'style/sizes';

type LoopCarouselProps = {
  padding?: number;
  slidesToShow: number;
  dir?: 'left' | 'right';
};

const Container = styled.div`
  overflow: hidden;
`;

const SliderWrapper = styled.div<LoopCarouselProps>`
  ${props => (props.slidesToShow === 2 ? `max-width: 1020px;` : `max-width: 1242px;`)};
  margin: 0 auto;
  ${mq.onlysp} {
    padding: 0 ${sizes.margin[49]};
  }
  ${({ dir }) =>
    dir === 'right' &&
    css`
      transform: rotate3d(0, 0, 1, 180deg);
    `}
  .slick-list {
    overflow: visible;
  }
  .slick-slide {
    ${({ dir }) =>
      dir === 'right' &&
      css`
        transform: rotate3d(0, 0, 1, 180deg);
      `}
    ${mq.onlypc} {
      min-width: 510px;
    }
    & > div {
      margin: 0 41px;
      ${mq.onlysp} {
        margin: 0 16px;
      }
    }
  }
`;

export const LoopCarousel: React.FC<LoopCarouselProps> = props => {
  const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: 'cubic-bezier(.77,.02,.24,.99)',
    speed: 1500,
    slidesToShow: props.slidesToShow,
    slidesToScroll: 1,
    centerPadding: `${props.padding}px`,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: breakpoint + 1,
        settings: {
          slidesToShow: Math.floor(props.slidesToShow / 2),
          variableWidth: false,
        },
      },
    ],
  };
  return (
    <Container>
      <SliderWrapper {...props}>
        <Slider {...settings}>{props.children}</Slider>
      </SliderWrapper>
    </Container>
  );
};
